@import "../../assets/css/styles.scss";

[title|="Cambiar contraseña"],
[title|="Datos personales"] {
  box-shadow: 0 0 14px 0 var(--color-sombra);
}

.card-body {
  background-color: whitesmoke;
  border-radius: 6px;
}
input[type="date"] {
  font-size: 14px;
}
h2.section {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  color: #969595;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  background-color: transparent;
  padding: 0 0 3px 0;
  font-weight: 700;
  line-height: 1.1;
}

.locate-me {
  color: #4b95a6;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  svg {
    margin-right: 8px;
    margin-bottom: 2px;
  }
  a {
    cursor: pointer;
    color: #4b95a6;
    text-decoration: none !important;
    &:hover {
      color: #f2a20d !important;
    }
  }
}
#bookshop-save {
  .btn.btn-outline-secondary,
  .btn.btn-info {
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1em;
    border-radius: 6px;
    padding: 9px 12px;
    margin: 0 5px 5px 0;
    font-size: 14px;
  }
  #bookshop-save--cancel:hover {
    color: #000;
    border-color: #000;
    background-color: transparent;
  }
  #bookshop-save--save:hover {
    color: #fff;
    border-color: #000;
    background-color: #000;
  }
}
#addresses {
  .addresses-container {
    padding: 16px 20px;
    background-color: #f1f1f1;
    p {
      margin-bottom: 5px;
      font-size: 14px;
      a {
        cursor: pointer;
        text-decoration: none;
        color: #4b95a6;
        &:hover {
          color: #f2a20d;
        }
      }
    }
  }
}
.section {
  .carouselBtn {
    width: 100%;
    margin-left: 2px;
    justify-content: space-between;
  }
}
.ordersRow {
  padding: 0px 0px;
  > div > div {
    height: 35px;

    span {
      position: relative;
      top: 4px;
      @include media-breakpoint-down(sm) {
        font-size: 11.5px;
      }
    }
  }
  .tableTitle {
    font-weight: bolder;
  }
  svg {
    color: #4b95a6;
    font-size: 26px;
    &:hover {
      color: #f2a20d;
    }
  }
}
