.carouselBtn {
  margin: 5px 10px;
  &:first-child {
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    padding-top: 13px;
    margin: 15px 10px;
    font-size: 18px;
    background: transparent !important;
    border: 2px solid #000000;
    border-radius: 2px;
    color: rgb(41, 41, 41);
    svg {
      position: relative;
      top: 3px;
      font-weight: bolder;
      font-size: 22px;
    }
  }
}
