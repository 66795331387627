@import "../../assets/css/styles.scss";

.schedule-row {
  .filter-col {
    h1 {
      font-weight: 900;
      font-size: 34px;
      margin: 0px;
    }
    hr {
      width: 16%;
      height: 4px;
      border-top: 3px solid #f2a20d;
    }
    p {
      position: relative;
      top: 3px;
    }
    input,
    > div:nth-of-type(2) div {
      background: #f8f8f8;
    }
  }
  .activitiesCol {
    > div:first-child {
      max-height: 450px;
      overflow: hidden;
    }
    h3 {
      font-size: 28px;
    }
    svg {
      font-size: 20px;
      position: relative;
      top: 4px;
    }
    .descriptionCard {
      p:first-child {
        font-size: 11.5px;
        font-weight: bolder;
      }
      h5 {
        font-weight: bolder;
      }
      svg {
        font-size: 14px;
        position: relative;
        top: 0px;
      }
      span {
        font-size: 11px;
      }
    }
    .cardImg {
      width: 89.2%;
      position: relative;
      left: 5.4%;
      @include media-breakpoint-down(md) {
        max-width: 70%;
        left: 14%;
      }
    }
    .noItems {
      h1 {
        position: relative;
        left: 10%;
      }
      svg {
        position: relative;
        left: 40%;
        font-size: 100px;
      }
    }
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      &--long {
        @extend .text;
        -webkit-line-clamp: 6; 
      }
    }
  }
}
.eventDetail {
  >div:first-child >div {
    max-height: 56vw;
    @include media-breakpoint-down(md) {
      max-height: 46vw;
    }
    @include media-breakpoint-down(xs) {
      max-height: 53vw;
    }
  }
  h3 {
    font-size: 28px;
  }
  svg {
    position: relative;
    top: 4px
  }
  img {
    @include media-breakpoint-down(md) {
      max-width: 70%;
    }
  }
}
.relatedBookRow {
  background: #F2F4F4;
  >div:first-child {
    p {
      font-size: 20px;
      width: 100%;
      color: #f2a20d;
      border-bottom: 1px solid #f2a20d;
    }
  }
  img {
    width: 100%;
  }
}

