.buttonSearchPost {
  position: relative;
  left: 63vw;
  width: 150px;
  top: -69px;
}

.styleButtonPost {
  position: relative;
  left: 2vw;
  top: -15px;
}

.form-post {
  margin-left: -10vw;
}

.modifyButtonPost {
  position: relative;
  width: 150px;
  left: 58vw;
  top: -69px;
}

.positionFormPost {
  margin-left: -15vw;
}

.modifyPlaceholder {
  ::placeholder {
    font-size: 9px;
  }
}

button.positionDeleteQuery {
  position: relative;
  left: 56.5vw;
  top: -70px;
}
