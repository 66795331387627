.inputBar {
  border: 1px solid #dadada;
  border-radius: 6px;
  width: 110px;
  height: 26px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.5, #fff));
  >div:first-child {
    border-right: 1px solid #dadada;
  }
  >div:last-child {
    width: 31px;
  }
}
.unfoldedRow {
  .itemsCol {
    max-height: 350px;
    overflow: auto;
    border-left: 1px solid #d8d8d8;
    background: linear-gradient(0deg, rgba(209,209,209,0.8948074229691877) 2%, 
    rgba(209,209,209,0.7015301120448179) 5%, 
    rgba(209,209,209,0.5082528011204482) 10%, 
    rgba(209,209,209,0.32057773109243695) 15%, 
    rgba(209,209,209,0.16531162464985995) 21%, rgba(209,209,209,0) 25%);
  }
  .inputCol{
    background: linear-gradient(180deg, 
    rgba(190,190,190,0.8435749299719888) 7%,
    rgba(190,190,190,0.6671043417366946) 17%, 
    rgba(190,190,190,0.44861694677871145) 26%, 
    rgba(190,190,190,0.24693627450980393) 37%, 
    rgba(190,190,190,0.13769257703081228) 50%, 
    rgba(180,180,180,0.06766456582633051) 57%, rgba(173,173,173,0) 62%);
  }
}

