// @import "../../assets//css//styles.scss";
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins/_breakpoints";

.smReviewRow {
  .smReviewSubtitle {
    color: #969595;
    border-bottom: 1px solid #E5E5E5;
    font-size: 18px;
    font-family: 'LatoRegItalic';
  }
  .smReviewDescription {
    font-family: 'Spectral';
    min-height: 430px;
  }
  .detailRow {
    p {
      width: 100%;
      // border-bottom: 1px solid #969595;
      color: #969595;
      margin: 5px;
    }
    ul {
      padding: 0px 5px;
    }
    li {
      list-style: none;
      color: #969595;
      font-size: 14px;
      padding-left: 6px;
      margin-bottom: 6px;
    }
    a {
      color: #006deb;
    }
  }
  .relatedItem {
    background: #F2F4F4;
    .cover-container {
      box-shadow: none;
    }
    .subTitle {
      width: 100%;
      color: #F2A20D;
      border-bottom: 1px solid #F2A20D;
      font-size: 19px;
    }
    img {
      max-width: 90%;
    }
    .authors {
      font-size: 14px;
      color: #969595;
    }
    .description {
      font-size: 14px;
    }
    button {
      width: 40%;
      min-width: 40%;
      // background: #4b95a6;
      border: none;
      // color: #fff;
      font-weight: 300;
      border-radius: 6px;
      font-size: 12px;
      margin-top: 13px;
      padding: 4px 0px;
      float: left;
    }
  }
}

