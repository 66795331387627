.buttonSearch {
  width: 15.3%;
  position: relative;
}

.modifyPositionSearch {
  position: relative;
  left: 62vw;
  top: -59px;
  width: 15%;
}

.styleButtonSearch {
  position: absolute;
  left: 54%;
  top: 76.5px;
}

.buttonCollapse {
  width: 100%;
  position: relative;
}

.styleInputCheckbox {
  width: 120px;
}

.utilitiesWidth {
  width: 225px;
}

.widthCheckbox {
  width: 15px;
  margin-left: 5px;
  position: relative;
  top: 9px;
}

.modifyMarginForm {
  margin-left: -226px;
  display: flex;
  position: relative;
}

.deleteQueryParams {
  border: 2px solid #17a2b8;
  width: 72px;
  height: 39px;
  color: #17a2b8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 60vw;
  top: -59px;

  &:hover {
    background-color: #17a2b8;
    color: white;
  }
}

.form-position {
  width: 40vw;
  display: flex;
  margin-left: -155px;
}
.buttonSearch {
  position: relative;
  left: 66vw;
  top: -60px;
}

.styleButtonSearch {
  position: relative;
  left: 2vw;
  top: -16px;
}

.stylePlaceholder {
  font-size: 13px;
}
