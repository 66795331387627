@font-face {
  font-family: "Spectral";
  src: url(../fonts/Spectral-Regular.ttf);
}

@font-face {
  font-family: "SpectralLight";
  src: url(../fonts/Spectral-LightItalic.ttf);
}

@font-face {
  font-family: "SpectralItalic";
  src: url(../fonts/Spectral-Italic.ttf);
}

@font-face {
  font-family: "Lato";
  src: url(../fonts/Lato-Light.ttf);
}

@font-face {
  font-family: "LatoReg";
  src: url(../fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "LatoRegItalic";
  src: url(../fonts/Lato-Italic.ttf);
}

body {
  font-family: LatoReg !important;
  height: 80vh !important;
}

.spectral {
  font-family: SpectralItalic !important;
}

.spectralReg {
  font-family: Spectral !important;
}

.lato {
  font-family: LatoReg !important;
}

button {
  border: medium none;
}

.container-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;

  h1 {
    font-size: 120px;
    font-weight: bold;
  }

  h2 {
    font-size: 35px;
    font-weight: 100;
  }
}

.alert-danger {
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  margin: 10px !important;
}

.alert-success {
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  margin: 10px !important;
}

.margin-top-sm {
  margin-top: 20px;
}
.margin-bottom-sm {
  margin-bottom: 15px;
}

.margin-top-md {
  margin-top: 40px;
}

.margin-top-xs {
  margin-top: 10px;
}

.margin-right-xs {
  margin-right: 5px;
}

.margin-right-md {
  margin-right: 40px !important;
}

.padding-top-md {
  padding-top: 40px;
}

.margin-bottom-md {
  margin-bottom: 40px;
}

.margin-md {
  margin-top: 18px;
  margin-bottom: 40px;
}

.padding-sm {
  padding: 10px;
}

.icon-md {
  font-size: 25px;
  margin: 5px;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.height-limit {
  height: 100%;
}

.icon-input {
  position: absolute;
  font-size: 20px !important;
  color: #ced4da;
  left: 1.35rem;
  top: 0.5rem;
}

.input-text {
  font-size: 0.8rem !important;
  padding: 1rem 0.75rem !important;
}

.input-text-icon {
  border-radius: 1rem !important;
  font-size: 0.8rem !important;
  padding: 1rem 0.75rem !important;
  padding-left: 1.8rem !important;
}

.shadow-bottom {
  box-shadow: 0 4px 20px -10px #ced4da;
}

.bg-transparency {
  opacity: 1;
}

.title {
  margin-top: 35px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-family: var(--fuente-primaria);
  }
}

.divider-title {
  background: url("../img/divider.png");
  background-size: cover;
  background-position: center center;
  width: 220px;
  height: 25px;
}

.text-primary {
  color: var(--texto-primario) !important;
}

.color-primary {
  color: var(--texto-primario) !important;
}

.text-bold {
  font-weight: bold;
}

.text-small {
  font-size: 11px;
  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}

.v-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-more {
  text-decoration: none;
  font-size: 14px;
  @include media-breakpoint-down(md) {
    font-size: 17px;
  }

  &:hover {
    text-decoration: none !important;
    color: var(--texto-primario);
    font-weight: bold;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.notice-message {
  margin: auto;
  padding-top: 65px;
  padding-bottom: 45px;
  text-align: center;
}

.btn-round {
  border-radius: 1rem !important;
}

.btn-pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba($color: #000, $alpha: 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($color: #000, $alpha: 0);
  }

  100% {
    transform: scale(0.9);
  }
}

.btn-logged {
  > button {
    background-color: var(--color-primario) !important;
    border-color: var(--color-primario);

    &:before {
      display: none !important;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0.5em;
  }
}
.aside-title {
  width: 100%;
  border-bottom: 1px solid #f2a20d;
  a,
  span {
    color: rgb(59, 59, 59);
    font-family: Spectral;
    font-size: 21px;
    font-weight: 600;
    width: 100%;
  }
}
.blue-btn {
  background: #4b95a6 !important;
  border: 1px solid #4b95a6 !important;
  border-radius: 6px !important;
  &:hover {
    background: #f2a20d !important;
    border: 1px solid #f2a20d !important;
  }
}
.orange-btn {
  background: #f2a20d !important;
  border: 1px solid #f2a20d !important;
  &:hover {
    background: #4b95a6 !important;
    border: 1px solid #4b95a6 !important;
  }
}
.white-btn {
  background: #fff !important;
  border: 1px solid #969595 !important;
  color: #969595 !important;
  &:hover {
    background: #fff !important;
    border: 1px solid #f2a20d !important;
    color: #f2a20d !important;
  }
}
.textInfo {
  max-width: 90%;
  color: #7c7c7c;
  font-size: 13.5px;
  p {
    font-size: 11.5px;
  }
}
.blue {
  color: #4b95a6 !important;
}
.orange {
  color: #f2a20d !important;
}
.inputTitle {
  font-size: 16.5px;
  font-weight: 900;
}
.dropdown-menu {
  overflow: hidden;
}
.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 220;
  height: 100vh;
  overflow: hidden;
  background: #000000b2;
}
.trueDiv {
  background: #80c26c;
  // border: 1px solid #d6e0cb;
  border-radius: 14px;
  height: 21px;
  padding: 1px 9px;
  p {
    color: white;
    font-size: 13px;
  }
}
.falseDiv {
  background: #db6b6b;
  border-radius: 14px;
  height: 21px;
  padding: 1px 9px;
  p {
    color: white;
    font-size: 13px;
  }
}

.mainDiv {
  max-width: 1520px;
}
.libelistaButtonYellow {
  background: #f2a20d;
  font-size: 0.8em;
  &:hover {
    background-color: #4b95a6;
  }
}
.libelistaButtonBlue {
  background: #4b95a6;
  font-size: 0.8em;
  &:hover {
    background-color: #f2a20d;
  }
}
