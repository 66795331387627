.library-card {
  padding: 10px;
  h5 {
    font-size: 18px;
    color: #231f20;
    font-weight: 900;
    padding: 12px;
    margin-bottom: 10px;
    min-width: 90%;
  }
  .img-col {
    min-height: 156px;
    background-size: 75%;
    background-position: center center;
  }
  .info-row {
    background: #f3f5f5;
    ul {
      padding-left: 12px;
      width: 95%;
    }
    li {
      list-style: none;
      padding: 8px 0px 12px;
      border-bottom: 1px solid #dfdfdf;
      font-size: 14px;
      color: #231f20;
      width: 100%;
      &:last-child {
        border-bottom: none;
      }
      svg {
        margin-right: 3px;
        color: #a7a7a7;
        font-size: 16px;
      }
    }
  }
  .icons-col {
    padding: 0px;
    > div {
      width: 20%;
      background: #dee0e0;
      height: 50px;
    }
    svg {
      font-size: 20px;
      color: #4b95a6;
      position: relative;
      left: 30%;
      top: 23%;
      &:hover {
        color: #f2a20d;
      }
    }
  }
}
