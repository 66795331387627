@import "./vendor/boostrap";

.store- {
  &item {
    height: 100%;
    padding-top: 1.8rem;
    display: flex;
    flex-direction: column;

    &__link {
      color: black;

      &:hover {
        color: black;
      }
    }

    @include media-breakpoint-down(md) {
      align-items: center;
    }

    > a {
      width: 85%;
      min-width: 90%;
      margin: auto;
      &:hover {
        text-decoration: none;
      }
      button {
        width: 76%;
        min-width: 76%;
        background: #4b95a6;
        border: none;
        color: #fff;
        text-transform: uppercase;
        font-weight: 300;
        border-radius: 6px;
        font-size: 12px;
        margin: auto;
        padding: 4px 0px;
        &:hover {
          background-color: #f2a20d;
        }
      }
    }
    .goldenBtn {
      background: #f2d857 !important;
      border: 1px solid #f2d857;
      color: #321f20;
    }
    .goldenBtn:hover {
      background: #f28c0f !important;
      border: 1px solid #f28c0f;
      color: #321f20;
    }
  }

  &cover {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.img-container {
  cursor: pointer;
  box-shadow: 0 0 1px 1px rgb(223, 223, 223);

  img {
    z-index: 1;
    width: 160px;
    height: 250px;
    object-fit: cover;
  }

  &:hover span {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: transform 0.5s ease, opacity 1.3s ease;
    transition: transform 0.1s ease, opacity 0.1s ease;
  }

  .overlay {
    opacity: 0;
    background: rgba($color: #000, $alpha: 0.4);
    position: absolute;
    margin: auto;
    width: 0px;
    height: 0px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: 0.4s ease;
    transition: 0.2s ease;
    z-index: 1;
  }

  span {
    color: #fff;
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 30px;
    line-height: 30px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 80px;
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: transform 0.2s ease, opacity 0.2s ease;
    transition: transform 0.2s ease, opacity 0.2s ease;
    z-index: 2;

    h5 {
      margin: 10px;
    }

    button + a {
      margin-left: 0.5em;
    }
  }
}

.limit-long {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.img-caption {
  margin: 0;
  padding: 0.2em 0.2em 0;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  margin-bottom: 12px;

  > div {
    flex: 1 1 auto;
    padding: 0px;
  }

  .title,
  .subtitle {
    text-align: center;
  }

  .title {
    color: #000;
    font-size: 18px;
    font-family: SpectralItalic, serif;
    font-weight: 600;
    line-height: 1.2em;
    padding: 0px 14px;
    margin-top: 8px;
    &:first-letter {
      text-transform: uppercase;
      display: block;
    }
  }

  .subtitle {
    color: #838383;
    font-size: 15px;
    font-family: Lato, sans-serif;
    font-weight: bolder;
    line-height: 1.2em;
    margin-bottom: 16px;
    margin-top: 0px;
  }

  h5,
  h6 {
    margin-bottom: 0;
    margin-top: 4px;
  }

  button {
    max-width: 322px;
    margin: auto;
  }

  & + p {
    text-align: center;
    margin: 0;
    font-weight: 600;
    font-family: "Lato";
    font-size: 16.8px;
    color: #f2a20d;
  }

  @include media-breakpoint-down(md) {
    max-width: 80%;
  }
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}
.trophyDiv,
.recommendedDiv {
  position: absolute;
  top: 20.7%;
  left: 43%;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: #f2a20d;
  z-index: 100;
  svg {
    color: #fff;
    position: relative;
    left: 8px;
    top: 4px;
    font-size: 20px;
  }
  @include media-breakpoint-down(md) {
    left: 44%;
  }
  @include media-breakpoint-down(xs) {
    left: 45.5%;
  }
}
.recommendedDiv {
  top: 62%;
}
.prizeName {
  text-transform: uppercase;
  font-size: 17px;
  color: #f2a20d;
  height: 84px;
  max-height: 84px;
  margin-bottom: 6px;
  text-align: center;
  padding: 0px 17px;
}
.img-review {
  img {
    width: 192px;
    height: 278px;
  }
}
.markers {
  position: absolute;
  top: 190px;
  left: calc((100% - 160px) / 2);
  writing-mode: vertical-rl;
  z-index: 1;
  min-height: 65px;
  transform: rotate(180deg);
  &-novelty {
    background: #f2d857;
    color: #000;
  }
  &-ebook {
    background: #000;
    color: #f2d857;
  }
  &-detail {
    top: 15px;
    left: 0;
  }
  p {
    padding: 3px;
    margin: 0;
    text-align: center;
    font-size: 12px;
  }
}
