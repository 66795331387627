@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.findLibRow {
  background: #f8f8f8;
  > div {
    position: relative;
    right: 2%;
  }
  input {
    width: 92%;
  }
  svg {
    font-size: 13px;
    position: relative;
    top: 4px;
    color: #4b95a6;
  }
  p {
    font-size: 13px;
    font-weight: 600;
    color: #4b95a6;
  }
  button {
    background: #f2a20d !important;
    border: #f2a20d;
    border-radius: 6px;
    width: 18%;
    font-size: 14px;
    &:hover {
      background: #4b95a6 !important;
      border: #4b95a6;
    }
  }
  div div div:nth-of-type(3) {
    position: relative;
    bottom: 8px;
  }
  @include media-breakpoint-down(lg) {
    > div:first-child,
    > div:nth-of-type(2) {
      position: relative;
      right: 8%;
    }
  }
  @include media-breakpoint-down(sm) {
    > div:nth-of-type(2) img {
      width: 240px;
    }
    > div:first-child,
    > div:nth-of-type(2) {
      right: 5%;
    }
  }
  @include media-breakpoint-down(xs) {
    > div:nth-of-type(2) img {
      width: 210px;
    }
    > div:first-child,
    > div:nth-of-type(2) {
      right: -4%;
    }
  }
}
