.subTitleCol {
	* {
    border-bottom: 1px solid #f2a20d;
    color: #f2a20d;
  }
  h2 {
    width: 100%;
    position: relative;
    top: 8px;
    padding-left: 7px;
  }
  img {
    width: 32px;
    height: 32px;
    position: relative;
    top: 15px;
    padding-bottom: 4px;
  }
}
.ageSelectors {
  p {
    margin-left: 10px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  p:before {
    background: #fff;
    content: "\2022";
    border-radius: 100%;
    width: 1em;
    height: 1em;
    display: inline-block;
    padding: .25em;
    line-height: 1;
    margin-right: .5em;
    border: 1px solid #321F20;
    padding-top: 0;
    list-style: none;
    color: #fff;
  }
  p.active:before {
    background: #F2D857;
    content: "\2022";
    border-radius: 100%;
    width: 1em;
    height: 1em;
    display: inline-block;
    padding: .25em;
    line-height: 1;
    margin-right: .5em;
    border: 1px solid #F2D857;
    padding-top: 0;
    list-style: none;
    color: #F2D857;
  }
  .active {
    font-weight: bolder;
  }
}
