@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.infoImg {
  @include media-breakpoint-up(lg) {
    position: relative;
    top: 70px;
    left: 25px;
  }
  @include media-breakpoint-only(xs) {
    position: relative;
    // top: 70px;
    left: -75px;
  }
}
.infoTitle {
  font-family: SpectralLight;
  font-weight: 900;
  font-size: 37px;
}
hr {
  border-top: 4px solid #f2a20d;
  width: 4%;
  float: left;
  position: relative;
  bottom: 10px;
}
.infoRow {
  font-family: Spectral;
  h3 {
    font-family: LatoReg;
  }
  h2 {
    font-family: LatoReg;
    font-size: 24px;
  }
  .card-header {
    background: #e5e5e5;
    padding: 0px;
    .btn {
      color: #000;
      font-family: LatoReg;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .form-control {
    background: #f8f8f8;
  }
}

// #F2A20D
// #4B95A6
