.header__products {
  #dropTrolley {
    display: flex;
    justify-content: center;
    float: right;
    min-width: 220px;
    border-radius: 8px;
    padding: 8px 16px 8px 20px;
    margin-bottom: 5px;
    background-color: #f2a20d;
    color: #fff;
    z-index: 1;

    p {
      font-size: 14px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .divider {
        margin: 0 5px;
      }
      span {
        margin-bottom: 0;
      }
      .number-products {
        font-weight: 600;
      }
    }
    .fas-shopping-bag {
      font-size: 28px;
      margin: 0 0 0 10px;
    }
  }
  #trolleyArea {
    overflow-y: auto;
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    z-index: 1000;
    min-width: 325px;
    max-height: 400px;
    background-color: whitesmoke;
    padding: 5px;
    border: 2px solid #969595;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.2);
    .modal-header {
      padding: 0.5rem;
      border-bottom: 1px solid #e7e7e7;
      .close {
        padding: 0;
        .fas-closing {
          position: absolute;
          left: 0px;
          top: 0px;
          margin: 2px;
        }
      }
      .section.cart {
        padding: 20px 10px 5px 15px;
        margin-bottom: 0;
        font-size: 16px;
        display: inline-block;
      }
    }
    .modal-body {
      h5 {
        text-align: center;
        margin-bottom: 15px;
      }
      .cart-product {
        display: flex;
        margin-bottom: 16px;
        &__item {
          img {
            width: 100%;
            height: auto;
          }
          .details {
            span {
              font-size: 14px;
            }
            &-title {
              a {
                font-family: "Spectral", serif !important;
                color: #321f20;
                font-size: 16px;
                line-height: 22px;
                font-style: italic;
                font-weight: 700;
                text-decoration: none;
                &:hover {
                  color: #f2a20d;
                }
              }
            }
            &-author {
              color: #969595;
            }
            &-price {
              .quantity {
                font-weight: 400;
              }
              .unitPrice {
                color: #f2a20d;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      border-top: 1px solid #e7e7e7 !important;
      &__link {
        color: #fff;
        padding: 7px 12px;
        font-size: 14px;
        border-radius: 6px;
        text-decoration: none;
        &:hover {
          background-color: #f2a20d !important;
        }
      }
    }
  }
}
