.favorites {
  width: 85%;
  h1 {
    font-weight: 900 !important;
    font-family: SpectralItalic;
  }
}
.noFavorites {
  position: relative;
  left: 5%;
  img {
    margin: auto;
  }
  h2 {
    font-family: Spectral;
  }
  button {
    background: #4b95a6 !important;
    border: 1px solid #4b95a6;
    width: 40%;
    &:hover {
      background: #f2a20d !important;
      border: 1px solid #f2a20d;
    }
    a {
      color: #fff;
    }
  }
}
