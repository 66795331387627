.loader-section {
  z-index: 1;
  background-color: rgba(white, 0.5);
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;

  &__container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    color: var(--secondary);
    display: block;
    margin-top: 0.5em;
    font-weight: bold;
  }
}
