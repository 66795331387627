.positionSearch {
  position: absolute;
  left: 43vw;
}

.buttonDeleteQuery {
  border: 2px solid #17a2b8;
  width: 72px;
  height: 39px;
  color: #17a2b8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 49vw;
  top: -39px;

  &:hover {
    background-color: #17a2b8;
    color: white;
  }
}

.colorGray {
  color: #cecece;
}