.orderTable {
  >div >div {
    height: 39px;
    span, h6, h3 {
      position: relative;
      top: 7px;
    }
  }
  .tableTitle h6 {
    color: #969595;
  }
  .tableBorder {
    border-bottom: 2px solid #ddd;
  }
  .borderBottom {
    border-bottom: 1px solid #ddd;
  }
}