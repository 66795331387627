.register {
  .form-control {
    background: #f8f8f8;
    height: 34px;
    width: 85%;
    border: 1px solid #e2e2e2;
  }
  .form-label {
    font-family: LatoReg;
    font-weight: 600;
    font-size: 14px;
  }
  p {
    font-size: 15px;
  }
  .checkDiv p {
    font-weight: 900;
  }
  nav {
    width: 85%;
    .nav-item {
      max-width: 49%;
    }
  }
  .asideCol {
    background: #f2f4f4;
    position: relative;
    top: 80px;
    height: fit-content;
    img {
      color: #f2a20d;
    }
    > div > div {
      text-align: center;
      p {
        font-size: 13px;
      }
    }
  }
  > div:first-child > button {
    position: relative;
    left: 30%;
    padding: 4px 12px;
    font-size: 13px;
    background: #f2a20d !important;
    border: 1px solid #f2a20d;
    &:hover {
      background: #4b95a6 !important;
      border: 1px solid #4b95a6;
    }
  }
  .textInfo {
    text-align: center;
  }
  .feedback {
    display: flex;
    justify-content: center;
    color: #dc3545;
    text-align: center;
    background: #ffbaba;
    padding: 8px;
    width: 55%;
    border-radius: 10px;
  }
}
