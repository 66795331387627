@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.alert-success {
  margin-top: 0px !important;
}
.loginModal {
  .modal-content {
    border-radius: 5px !important;
    max-width: 400px;
    position: relative;
    top: 20px;
    left: 30px;
  }
}

.modal-title {
  width: 100% !important;
}
.modal-body {
  padding-bottom: 0px !important;
  input {
    background: #f8f8f8;
    &::placeholder {
      font-size: 14.5px;
      color: #969595;
    }
  }
}
.modal-footer {
  padding-top: 0px;
}
.headerText {
  justify-content: center;
  display: flex;
  align-items: center;
  align-content: center;
  font-family: sans-serif;
  font-weight: bold;
}
.footerLogin {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-content: center;
  button {
    background: #4b95a6 !important;
    border-radius: 6px;
    padding: 2px;
  }
}
.footerOptions {
  border-top: 1px solid #dee2e6;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.boxText {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
.questionText {
  margin-right: 1px;
  cursor: default;
  font-family: sans-serif;
  font-size: 13px;
  color: black;
  font-weight: 500;
}
.linkText {
  margin-left: 1px;
  color: #4b95a6;
  font-size: 13.6px;
  cursor: pointer;
  // font-family: sans-serif;
  // font-weight: bold;
  transition: all 0.3s ease-in-out;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    opacity: 0.3;
  }
}
.feedback {
  display: flex;
  justify-content: center;
  color: #dc3545;
  text-align: center;
}

.modal-footer {
  border-top: none !important;
}

@include media-breakpoint-down(md) {
  .boxText {
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
