@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

h2 {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.stepper {
  overflow: visible !important;
  margin-bottom: 25px;
  .stepper__header {
    height: 90px;
    padding-top: 22px;
    @include media-breakpoint-down(sm) {
      button {
        padding-left: 5px;
        padding-right: 5px;
      }
      hr {
        margin: 0px;
      }
    }
  }
  .stepper__content__actions {
    justify-content: space-between;
    button span {
      margin: 0px;
    }
    @include media-breakpoint-down(md) {
      justify-content: space-between;
    }
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      margin-top: 1em;
      margin-bottom: 2em;
      justify-content: center;
      button {
        margin: 3px !important;
      }
    }
  }
}
.titleResume {
  font-family: sans-serif;
  font-weight: bolder;
  font-size: 25px;
  text-align: center;
}
.resumeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .itemsContainer {
    width: 100%;
  }
}

.payment-options {
  // border: 1px solid var(--color-secundario);
  margin-top: 1em;
  padding-top: 1em;
}

.list-group .list-group-item {
  label {
    width: 100%;
  }
}

.card {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.paypal-buttons {
  display: flex !important;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  min-width: 50% !important;
  max-width: 50% !important;

  @include media-breakpoint-down(md) {
    margin-left: auto;
    margin-right: auto;
    min-width: 50% !important;
    max-width: 50% !important;
  }

  @include media-breakpoint-down(sm) {
    margin-left: auto;
    margin-right: auto;
    min-width: 50% !important;
    max-width: 50% !important;
  }
}

.shippingCosts {
  margin: 18px 0px 22px;
  justify-content: space-between;
  div:first-child {
    display: flex;
    max-width: 60%;
  }
  div:last-child {
    display: flex;
    justify-content: flex-end;
    max-width: 30%;
  }
}
.priceCol {
  .card-body {
    box-shadow: none !important;
    background: #f2f4f4;
    color: #321f20;
  }
  .aside-title {
    span {
      font-family: Lato;
      font-weight: 900;
      font-size: 24px;
    }
  }
}
.discount {
  .form-control {
    background: #f6f6f6;
    width: 65%;
  }
  button {
    font-size: 13px;
  }
  svg {
    position: relative;
    top: -2px;
  }
  > div .orange-btn {
    font-size: 14px;
  }
}
.wizard {
  background-color: transparent;
  padding: 0;
  width: 100%;
  margin: 1em auto;
  clear: both;
  border-bottom: none;
  position: relative;
  left: 3px;
  .grayArrow::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: 0;
    border: 26.5px solid transparent;
    border-right-width: 0;

    z-index: 2;
    border-left-color: #e5e5e5;
  }
  .grayArrow::before {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: 0;
    border: 27px solid transparent;
    border-right-width: 0;

    z-index: 1;
    transform: translateX(4px);
    border-left-color: #fff;
    margin: 0;
  }
  li {
    position: relative;
    padding: 0;
    padding-right: 3px;
    margin: 4px 4px 4px 0;
    width: 32.9%;
    float: left;
    text-align: center;
    position: relative;
    padding: 1em 0.8em 0.8em 2.5em;
    color: #969595;
    background-color: #e5e5e5;
    border-color: #c7c7c7;
    border-radius: 0;
    a {
      color: #969595;
    }
  }
  .active {
    color: #fff !important;
    background-color: #4b95a6 !important;
    border-color: #4b95a6 !important;
    border-bottom: none !important;
    a {
      color: #fff;
    }
  }
  .active::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: 0;
    border: 26.5px solid transparent;
    border-right-width: 0;

    z-index: 2;
    border-left-color: inherit;
  }
  .active::before {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: 0;
    border: 27px solid transparent;
    border-right-width: 0;

    z-index: 1;
    transform: translateX(5px);
    border-left-color: #fff;
    margin: 0;
  }
}
.cart-item {
  height: 287px;
  border: 1px solid #e5e5e5;
  margin-top: 12px;
}
.tabs-row {
  .tab-pane {
    button:first-child {
      width: 98%;
      justify-content: space-between;
    }
    .white-btn {
      font-size: 14px;
      width: 25%;
    }
  }
  .libraryCheck {
    .form-check {
      position: relative;
      top: 20px;
    }
    div {
      p:first-child {
        font-size: 18px;
        font-weight: 900;
      }
      p:nth-of-type(2) {
        font-size: 14.5px;
        color: rgb(155, 155, 155);
      }
    }
  }
  .giftDiv div,
  .tab-content div {
    p {
      font-size: 16.5px;
      font-weight: 900;
    }
  }
  img {
    height: max-content;
    position: relative;
    top: 4px;
  }
  img + p {
    color: #a6c5c4;
  }
  .tab-content {
    border-bottom: 1px solid #d4d4d4;
    border-left: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
    padding: 20px 5px;
  }
  .giftDiv {
    border: 1px solid #d4d4d4;
    padding: 20px 5px;
    border-radius: 6px;
    textarea {
      min-height: 130px;
    }
  }
  .form-control {
    background: #f8f8f8;
  }
  h2 {
    font-size: 30.5px;
  }
}
.nav-tabs {
  .nav-item.nav-link {
    background: #f8f8f8;
  }
}

.textDiscount {
  color: #23a62e;
}

.borderDiscount {
  width: 100%;
  border-top: 1px solid #f2a20d;
  padding-top: 10px;
}
