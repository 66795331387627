@import "../../assets/css/styles.scss";

.blogContainer {
  width: 100%;
  display: flex;
  align-content: center;
  .containerPostList {
    width: 65%;
    .cardBlog {
      .img {
        height: 300px;
      }
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (min-width: 991px) {
      width: 70%;
    }
    @media (min-width: 1199px) {
      width: 75%;
    }
  }
  .columnPostList {
    width: 35%;
    height: 100%;

    // background-color: rgba($color: $primary-color, $alpha: 0.1);

    // box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.05);
    // padding: 25px 0px 25px 0px;
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (min-width: 991px) {
      width: 30%;
    }
    @media (min-width: 1199px) {
      width: 25%;
    }
    .blog-aside-categories {
      padding: 10px 10px 20px 10px;
      background-color: #f1f1f1;
      .media {
        margin-top: 15px;
        text-decoration: none;
        &-img {
          min-width: 50px !important;
          padding-right: 10px;
        }
        &-text {
          padding-top: 7px;
          margin-bottom: 0px;
          color: #321f20;
          font-size: 18px;
          &:hover {
            color: #f2a20d;
          }
        }
      }
    }
    .blog-aside-mostvisited {
      @extend .blog-aside-categories;
      margin-top: 20px;
    }
    .section-col {
      font-weight: 700;
      font-size: 20px;
      color: #321f20;
      border: none;
      border-bottom: 1px solid #f2a20d;
      background-color: transparent;
      margin: 16px 0;
      padding: 0 0 3px 0;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  .degradeHue {
    z-index: 2;
    background: rgb(64,64,64);
    background: linear-gradient(0deg, rgba(64,64,64,0.8911939775910365) 3%, rgba(64,64,64,0.7679446778711485) 21%,
    rgba(64,64,64,0.6334908963585435) 37%, rgba(64,64,64,0.47102591036414565) 52%, rgba(64,64,64,0.2637429971988795) 69%,
    rgba(64,64,64,0.0844712885154062) 86%, rgba(64,64,64,0.039653361344537785) 97%);
  }
}
.titleColumnPostTitle {
  width: 100%;
  text-align: center;
  font-family: "LatoReg", sans-serif;
  font-weight: bolder;
  font-size: 25px;
}
.titleColumnPostTitle.author {
  padding-bottom: 20px;
  border-bottom: 1px solid #000;
  margin-bottom: 25px;
}
.scrollBlogContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.05);
  overflow: auto;
  white-space: nowrap;
  background: #e5e5e5;
  @media (max-width: 600px) {
    .cardBlog {
      width: 100% !important;
      min-width: 95% !important;
    }
  }
}
