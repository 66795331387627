@import "../../assets/css/styles.scss";

.libraries-row {
  .filter-col {
    h1 {
      font-weight: 900;
      font-size: 34px;
      margin: 0px;
    }
    hr {
      width: 16%;
      height: 4px;
      border-top: 3px solid #f2a20d;
    }
    p {
      margin-bottom: 2px;
      font-weight: 900;
    }
    input,
    > div:nth-of-type(2) div {
      background: #f8f8f8;
    }
    button {
      width: 100%;
      margin: 10px 0px;
      text-transform: uppercase;
      border-radius: 6px;
      height: 35px;
      &:nth-of-type(1) {
        background: #4b95a6 !important;
        border: 1px solid #4b95a6;
        &:hover {
          background: #f2a20d !important;
          border: 1px solid #f2a20d;
        }
      }
      &:last-child {
        background: #f2a20d !important;
        border: 1px solid #f2a20d;
        &:hover {
          background: #4b95a6 !important;
          border: 1px solid #4b95a6;
        }
      }
    }
    svg {
      float: right;
      font-size: 35px;
      position: relative;
      right: 20px;
    }
  }
}
