.yearCol {
  p {
    font-weight: bolder;
    font-size: 18px;
  }
  .select { 
    width: 11%;
    padding-left: 15px;
    position: relative;
    top: -5px;
    & > div:last-of-type {
      z-index: 5;
    }
  }
}
