.inputFontSize::placeholder {
  font-size: 1rem !important;
  font-weight: 900;
}

.buttonDeleteQuery {
  border: 2px solid #17a2b8;
  width: 72px;
  height: 38px;
  color: #17a2b8;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 57vw;
  top: -52px;

  &:hover {
    background-color: #17a2b8;
    color: white;
  }
}

.searchAccordion {
  position: relative;
  left: -15px;
  top: -1px;
  width: 109%;
}

.form {
  width: 50%;
  margin-left: -135px;
  display: flex;
  flex-wrap: wrap;
}

.searchButton {
  position: relative;
  left: 62vw;
  top: -67px;
  width: 150px;
  height: 35px;
}

.styleButton {
  position: absolute;
  top: 107px;
}

.modifyMargin {
  margin-left: -205px;

  display: flex;
  flex-wrap: wrap;
}

.modifyPosition {
  position: relative;
  left: 57.5vw;
  width: 150px;
  top: -68px;
}
