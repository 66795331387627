.form-control {
  height: 38px;
  border-radius: 4px !important;
}
.btn-outline-dark:hover {
  color: var(--texto-secundario);
  background-color: var(--color-primario);
  border-color: #000;
}
.btn-outline-dark {
  color: var(--texto-primario);
  background-color: var(--color-secundario);
}
.nav-tabs {
  border-bottom: none;
}
h3 {
  font-size: 24px;
}
.navbar-nav {
  a {
    color: rgb(19, 19, 19) !important;
    margin: 0px 5px;
  }
}
