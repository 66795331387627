.libraries {
  >div {
    background: #f2f2f2;
    width: 490px;
    >div >div:nth-of-type(2) {
      border-bottom: 1px solid #c0c0c0;
      border-top: 1px solid #c0c0c0;
    }
    .input-group {
      width: 75%;
      span {
        color: aliceblue;
        font-size: 22px;
        padding: 5px 10px;
      }
    }
  }
  .librariesRow {
    >div {
      border-bottom: 1px solid #c0c0c0;
    }
    .accordion {
      svg {
        color: #008585;
        font-size: 19px !important;
        position: relative;
        top: -3px;
      }
      button {
        background: #F7F7F7;
      }
      a {
        text-decoration: none;
      }
      .card-body {
        border-top: 1px solid #c0c0c0;
      }
    }
  }
}