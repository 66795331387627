.modifyForm {
  width: 60vw;
  margin-left: 7px;
  position: absolute;
}

.modifyMarginForm {
  margin-left: 5px;
  position: absolute;
}

.btn-search {
  position: relative;
  top: -15px;
}

.modifyPosition {
  position: relative;
  top: -69px;
  width: 150px;
}
