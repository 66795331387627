.homeRow {
  .iconLink {
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    color: inherit;
    svg {
      color: #4b95a6;
    }
  }
  .titleCol {
    background: #f8f8f8;
    padding: 0px 7px;
    border: 1px solid #dfdfdf;
    svg {
      top: 4px;
      font-size: 18px;
    }
    & + div {
      border: 1px solid #dfdfdf;
      justify-content: space-between;
      h3 {
        color: #f2a20d;
      }
    }
  }
  .sellItemCol {
    background: #f8f8f8;
    svg {
      background: #64625f;
      font-size: 35px;
      color: #fff;
      border-radius: 4px;
    }
    a {
      color: #4b95a6;
    }
  }
}
