.cookies {
  position: fixed;
  bottom: 0px;
  z-index: 200;
  border-top: 2px solid gray;
  border-bottom: 2px solid #4b95a6;
  background: #fff;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookies-row {
  width: 85%;
  justify-content: center;
  position: relative;
  top: 15px;
  p {
    font-weight: bolder;
    color: #000;
  }
  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    p:first-child {
      background: #4b95a6;
      padding: 6px;
      border-radius: 6px;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background: #f2a20d;
      }
    }
    a {
      color: #4b95a6;
      text-decoration: none;
      margin-bottom: 16px;
      min-width: 65px;
      &:hover {
        color: #f2a20d;
      }
    }
  }
}
