.titlePanel {
  border-bottom: 1px solid #dfdfdf;
  max-height: 44px;
  h3 {
    color: #575655;
  }
}
.panelInput {
  height: 30px;
}
.greyBtn, 
.greyBtn:hover, 
.greyBtn:active, 
.greyBtn:focus {
  height: 30px;
  background: #F5F5F5;
  border: 1px solid #dfdfdf;
  color: #333333;
  padding: 2px 10px;
}