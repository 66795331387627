.styleSearch {
  position: relative;
  top: -53px;
  left: 64vw;
  width: 140px;
}

.modifySearch {
  width: 140px;
  position: relative;
  top: -53px;
  left: 60vw;
}

.styleForm {
  width: 60vw;
  margin-left: -135px;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
}

.buttonSearchStyle {
  position: relative;
  left: 15px;
  top: -16px;
}

.deleteQuery {
  border: 2px solid #17a2b8;
  width: 72px;
  height: 39px;
  color: #17a2b8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 58.5vw;
  top: -53px;

  &:hover {
    background-color: #17a2b8;
    color: white;
  }
}
