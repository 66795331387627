.listDetailRow {
  >div:nth-of-type(2)>div:first-child>div {
    background: #efefef;
    border: 1px solid #b9b9b9;
    border-radius: 5px;
    color: #4b95a6;
    height: 40px;
  }
  .custom-select, input {
    height: 30px;
    padding: 0px 25px;
  }
  .titleRow {
    border-top: 1px solid #cccccc;
    >div {
      height: 40px;
      background: white;
      color: #b9b9b9;
      &.active {
        background: #b9b9b9;
        color: white;
      }
      svg {
        font-size: 22px;
      }
    }
    >div:nth-of-type(1) {
      flex: 0 0 23%;
      max-width: 23%;
    }
    >div:nth-of-type(2) {
      flex: 0 0 18%;
      max-width: 18%;
    }
    >div:nth-of-type(3) {
      background: #fff;
    }
  }
}