.panelHeader {
  height: 110px !important;
  max-height: 110px !important;
  background: #F3F1EF;
  img {
    height: 90%;
    position: relative;
    top: 7px;
  }
  
  .dropdown-toggle.nav-link {
    color: #231F20;
    &:hover {
      color: #f2a20d;
    }
  }
  .dropCol {
    .dropdown.show {
      button {
        background: #dcdcdc !important;
        color: #808080 !important;
      }
      p {
        background: #eeeeee;
        color: #5c5c5c !important;
        width: 35%;
        text-align: center;
      }
    }
    .dropdown {
      button {
        background: #9C9B9C !important;
        border: 1px solid #9C9B9C;
        color: #fff !important;
      }
      .dropdown-menu {
        background: #dcdcdc !important;
        a {
          color: #808080;
        }
        
      }
    }
  }
 
  .btn-primary::after {
    display: none;
  }
}