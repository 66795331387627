@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

footer > .container-fluid {
  display: flex;
}

.subscription-row {
  margin-bottom: 15px;
  justify-content: center;
  width: 100%;
  padding-bottom: 65px;
  padding-top: 50px;
  @include media-breakpoint-down(xs) {
    padding-top: 15px;
    padding-bottom: 35px;
  }

  > div:nth-of-type(1) {
    padding: 15px;
    display: flex;
    justify-content: center;
    h3 {
      font-family: var(--fuente-primaria);
      color: #000;
      position: relative;
      top: 3px;
      @include media-breakpoint-down(xs) {
        font-size: calc(2.2vw + 20px);
      }
    }
    > div > div:nth-of-type(1),
    > div > div:nth-of-type(2) {
      display: flex;
      justify-content: center;
      @include media-breakpoint-down(sm) {
        position: relative;
        left: 14px;
      }
    }
    > div > div:nth-of-type(3) {
      .form-check,
      p {
        position: relative;
        left: 10%;
        max-width: 80%;
        font-size: 13.5px;
      }
      span {
        color: #4b95a6;
        cursor: pointer;
      }
    }

    img {
      width: 65px;
      height: 50px;
    }
  }
  .social-col {
    > .row {
      position: relative;
      top: 22px;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 20px;
      position: relative;
      left: 10%;
    }
    @include media-breakpoint-up(sm) {
      & div div {
        max-height: 25px;
      }
    }
    img {
      width: 25px;
      height: 25px;
      margin-left: 75%;
    }
    p {
      margin-left: 5%;
      margin-bottom: -1rem;
    }
    a {
      color: #000000;
      text-decoration: none;
    }
  }
  .appCol {
    padding: 15px;
    @include media-breakpoint-up(sm) {
      border-left: 1px solid rgb(105, 105, 105);
    }
    .phone-img {
      width: 45px;
      height: 65px;
    }
    div div div > div:last-child {
      @media (min-width: 992px) and (max-width: 1550px) {
        // position: relative;
        // left: 0px;
        p {
          position: relative;
          left: 25px;
        }
      }
      @include media-breakpoint-down(md) {
        p {
          position: relative;
          left: 36px;
        }
      }
      @include media-breakpoint-down(sm) {
        right: 0px;
      }
    }
  }
  > div:last-child,
  > div:nth-of-type(2) {
    display: flex;
    justify-content: center;
    right: 25px;
    align-items: baseline;
    @include media-breakpoint-up(lg) {
      border-left: 1px solid rgb(105, 105, 105);
    }
  }
  .alert-success {
    margin-top: 20px !important;
    max-width: 300px;
  }
  .form-control {
    width: calc(105px + 10vw);
    position: relative;
    right: 2%;
    background: rgba(235, 235, 235, 0.432);
    @include media-breakpoint-down(sm) {
      width: calc(140px + 12vw);
    }
  }
  button {
    position: relative;
    right: 80%;
    text-transform: uppercase;
    height: 35px;
    font-size: 14px;
    font-weight: 600;
    background: #f2a20d !important;
    border-color: #f2a20d;
    @include media-breakpoint-down(sm) {
      right: 75%;
    }
    &:hover {
      background-color: #4b95a6 !important;
    }
  }
}
.appImg {
  img {
    width: calc(70px + 4vw);
    margin-left: 10px;
  }
  @media (min-width: 1270px) {
    div:last-child {
      position: relative;
      right: 5%;
    }
  }
  @media (min-width: 577px) and (max-width: 730px) {
    div:last-child {
      position: relative;
      right: -15%;
    }
  }
  @include media-breakpoint-only(xs) {
    div:first-child {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.legalModal {
  .modal-content {
    width: 135%;
    position: relative;
    right: 13%;
  }
  .modal-header {
    padding-bottom: 4px;
  }
  h3 {
    color: #969595;
  }
}
