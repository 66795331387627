// DISPLAY OF SEARCH NAVIGATION MENU ON DIFERENT SCREEN SIZES

#search-bar {
  padding: 3px 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-top: 1px solid #e7e7e7;
  @include media-breakpoint-up(md) {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-top: 0px;
    .mobile-look,
    .desktop-look {
      border-bottom: 0px;
    }
  }
  .mobile-look,
  .desktop-look {
    border-bottom: 1px solid #e7e7e7;
    @include media-breakpoint-up(md) {
      border-bottom: none;
      margin-bottom: 0px !important;
    }
  }
  .mobile-look {
    @include media-breakpoint-up(lg) {
      display: none !important;
    }
  }
  .desktop-look {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }
}

// SEARCH BAR MOBILE SIZE
.header__search.mobile {
  display: flex;
  align-items: center;
  padding: 0px 15px !important;
  > form {
    width: 100%;
    .icon-input {
      color: #321f20;
    }
    .form-group {
      margin-bottom: 0;
      .input-text-icon {
        background-color: #f8f8f8 !important;
        color: #969595 !important;
        border-radius: 6px !important;
        // padding-left: 1rem !important;
        &:focus {
          outline: 1px solid #4b95a6;
          caret-color: #f2a20d;
        }
      }
    }
  }
}

// SEARCH BAR DESKTOP SIZE
.header__search.desktop {
  .search-desktop {
    display: flex !important;
    flex-direction: row !important;
    height: 38px !important;
    width: 100%;
    > form {
      width: 100%;
      .input-text-icon {
        background-color: #f8f8f8 !important;
        color: #969595 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        font-size: 14px;
        padding-left: 1rem !important;
        &:focus {
          caret-color: #f2a20d;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 8px rgba(102, 175, 233, 0.6);
        }
      }
    }
    #input-group-dropdown-2 {
      border-radius: 0 !important;
      border: 1px solid #ced4da;
      background-color: #f8f8f8 !important;
      color: #969595 !important;
      font-size: 14px;
      &:hover {
        color: #f2a20d !important;
      }
    }
    .dropdown-menu {
      min-width: 290px !important;
      font-size: 14px;
      left: auto;
      right: 0;
      .form-group {
        margin: 0 -10px 5px -10px !important;
        label {
          padding: 3px 0px;
          margin-bottom: 2px;
        }
        .form-check {
          &-label {
            font-weight: normal;
            padding: 1px 0px 3px 0px !important;
            cursor: pointer;
          }
        }
        .dropdown-divider {
          margin: 2px 0;
        }
      }
      .btn.btn-info {
        border-radius: 6px;
        width: 100%;
        margin-bottom: 18px;
        font-size: 14px;
        font-weight: bolder;
        &:hover {
          background-color: #f2a20d;
          border-color: #f2a20d;
        }
      }
    }
    .deskt-search {
      border-radius: 0 6px 6px 0;
      padding: 6px;
      &:hover {
        background-color: #f2a20d;
        border-color: #f2a20d;
      }
    }
    .icon-search {
      margin-bottom: 3px;
      height: 23px;
      width: auto;
      color: #fff !important;
    }
    label {
      color: #231f20;
      max-width: 100%;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: bold;
    }
    .dropdown-menu {
      margin-top: -1px;
      padding: 6px 20px;
      box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

// SEARCH BAR DESKTOP SIZE + SCROLL
.navbar-right {
  .showMiniBarSearch {
    z-index: 2;
    position: absolute;
    top: 10px;
    right: 240px;
    display: block;
    width: 335px;
    height: 38px;
    font-size: 0.75rem;
    .input-text-icon {
      padding-left: 1rem !important;
      &:focus {
        outline: 1px solid #4b95a6;
        caret-color: #f2a20d;
      }
    }
    &.animate {
      animation: searchBarAnimation 0.7s linear;
      line-height: 25px !important;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    }
    @keyframes searchBarAnimation {
      0% {
        width: 0;
      }
      100% {
        width: 335px;
      }
    }

    .instant-search.resultsSticky {
      width: 70vw !important;
      right: 0%;
      .contentTileSearch {
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 15px 20px 5px 20px;
        transition: all 0.3s ease-in-out;
        &:hover {
          border-radius: 15px;
          box-shadow: rgba(21, 22, 26, 0.24) 0px 0px 25px 3px;
        }
        &:active {
          opacity: 0.3;
        }
      }
    }
  }
}
