h1,
h2,
h3 {
  margin-top: 20px;
}

h1.page-title,
h2.page-title {
  font-family: "SpectralLight", serif;
  font-weight: 700;
}

.static-title {
  position: relative;
  font-size: 2.3em !important;
  margin-top: 5px;
  margin-bottom: 32px;
  &::before {
    content: "";
    background: #f2a20d;
    width: 48px;
    height: 3px;
    position: absolute;
    bottom: -16px;
  }
}

h2.page-subtitle {
  margin-bottom: 30px !important;
  text-align: center;
  // .titleSelect {
  font-family: sans-serif;
  font-weight: 500;
  // padding-bottom: 10px;
  // margin: 0 20px 20px 20px;
  // text-align: left;
  // }
}

//CAFE
.cafe {
  .cafe-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

//GALERIA
.gallery {
  .tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2em;
  }

  .galleryCategories {
    .searchGallery {
      margin: 20px;
    }
  }

  .tile {
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
    height: 255px;
    width: 30%;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-content: stretch;
    align-items: center;

    img {
      width: 100%;
      border-radius: 15px;
      transition: all 0.3s ease-in-out;
      transition: transform 500ms ease-out;
      box-shadow: 0 0 14px 0 var(--color-sombra);
      // // transition: 0.3s ease-in-out;
      object-fit: cover;

      &:active {
        opacity: 0.3;
      }
    }
  }
  @media (max-width: 991px) {
    .tiles {
      justify-content: space-evenly;
      align-content: stretch;
      align-items: center;
    }
    .tile {
      height: 30vw;
      width: 45%;
    }
    .contentTiles {
      flex-wrap: wrap-reverse !important;
    }
  }
  @media (max-width: 575px) {
    .tiles {
      flex-direction: column;
      align-items: center;
    }
    .tile {
      &:first-child {
        margin-top: 1.5em;
      }
      &:last-child {
        margin-bottom: 1.5em;
      }
      height: 50vw;
      width: 75%;
    }
    .contentTiles {
      flex-wrap: wrap-reverse !important;
    }
  }
}

//BLOG
.blog {
  .blogContainer {
    width: 100%;
    display: flex;
    align-content: center;
    .containerPostList {
      width: 100%;
      .cardBlog {
        .img {
          height: 300px;
        }
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .blog-aside {
      width: 25%;
      height: 100%;

      @media (max-width: 767px) {
        width: 100%;
      }

      &-categories {
        padding: 10px 10px 20px 10px;
        background-color: #f1f1f1;
        .media {
          margin-top: 15px;
          text-decoration: none;
          &-img {
            min-width: 50px !important;
            padding-right: 10px;
          }
          &-text {
            padding-top: 7px;
            margin-bottom: 0px;
            color: #321f20;
            font-size: 18px;
            &:hover {
              color: #f2a20d;
            }
          }
        }
      }
      &-mostvisited {
        @extend .blog-aside-categories;
        margin-top: 20px;
      }
      // background-color: rgba($color: $primary-color, $alpha: 0.1);

      // box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.05);
      // padding: 25px 0px 25px 0px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .titleColumnPostTitle {
    width: 100%;
    text-align: center;
    font-family: "LatoReg", sans-serif;
    font-weight: bolder;
    font-size: 25px;
  }
  .titleColumnPostTitle.author {
    padding-bottom: 20px;
    border-bottom: 1px solid #000;
    margin-bottom: 25px;
  }
  .section-col {
    font-weight: 700;
    font-size: 20px;
    color: #321f20;
    border: none;
    border-bottom: 1px solid #f2a20d;
    background-color: transparent;
    margin: 16px 0;
    padding: 0 0 3px 0;
  }
  .scrollBlogContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.05);
    overflow: auto;
    white-space: nowrap;
    background: #e5e5e5;
    @media (max-width: 600px) {
      .cardBlog {
        width: 100% !important;
        min-width: 95% !important;
      }
    }
  }
}

//NOSOTROS
.us-location {
  .address-info {
    display: flex;
    flex-direction: column;
  }
  .social {
    .social-icons {
      display: flex;
      justify-content: space-evenly;
      a {
        transition: all 0.5s ease-in-out;
        .icons-size  {
          font-size: 20px;
          border-radius: 6px;
        }
        .fb {
          &:hover {
            background-color: #3b5998;
            color: #fff !important;
            transition: all 0.5s ease-in-out;
          }
        }
        .inst {
          &:hover {
            background-color: #dd2a7b;
            color: #fff !important;
            transition: all 0.5s ease-in-out;
          }
        }
        .twit {
          &:hover {
            background-color: #1e8dee;
            color: #fff !important;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
  .side {
    &-square1 {
      background-color: #f1f1f1;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
    }
    &-square2 {
      background: #e5e5e5;
      padding: 32px 16px 26px 16px;
      .contact-info {
        font-weight: normal !important;
      }
    }
    .contact-info {
      font-weight: 600;
      a {
        font-weight: normal;
        text-decoration: none;
        color: #f2a20d;
        &:hover {
          color: #4b95a6;
        }
      }
      &__specific1 {
        font-weight: normal;
      }
      &__specific2 {
        font-size: 14px;
        font-weight: normal;
        color: #7c7a7a;
      }
    }
  }
}

//***** ERROR 404 *****
.static-empty {
  img {
    margin: 32px 0;
  }
  h2 {
    font-family: "Spectral", serif;
  }
  .gray {
    color: gray;
    margin-bottom: 32px;
  }
  .fit {
    padding-left: 10px;
    padding-right: 10px;
  }
  p {
    a {
      color: #4b95a6;
      text-decoration: none !important;
      &:hover {
        color: #f2a20d;
      }
    }
  }
  a.fit {
    padding-top: 12px;
    padding-bottom: 12px;
    width: 50%;
    max-width: 500px;
    min-width: 200px;
    margin-top: 40px;
    margin-bottom: 32px;
    border-radius: 6px;
    font-size: 16px;
    text-transform: uppercase;
  }
  .btn-info {
    &:hover {
      background-color: #f2a20d;
      border-color: #f2a20d;
    }
  }
}

//***** HEADER *****

// CONTACTO
.contact {
  .form-group {
    label {
      font-weight: bold;
    }
    .form-check {
      label {
        font-weight: normal;
        cursor: pointer;
      }
    }
  }
  .btn-dark {
    width: 250px;
  }
  .legal-info {
    margin-top: 2rem;
    border-top: 1px solid #e5e5e5;
    padding-top: 1.5rem;
    font-size: 14px;
    font-weight: normal;
    color: #969595;
    text-align: center;
  }
  .side {
    &-square1 {
      background-color: #f1f1f1;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
    }
    &-square2 {
      background: #e5e5e5;
      padding: 32px 16px 26px 16px;
      .contact-info {
        font-weight: normal !important;
      }
    }
    .contact-info {
      font-weight: 600;
      a {
        font-weight: normal;
        text-decoration: none;
        color: #f2a20d;
        &:hover {
          color: #4b95a6;
        }
      }
      span {
        font-size: 14px;
        font-weight: normal;
        color: #7c7a7a;
      }
    }
  }
}
