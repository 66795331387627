@import "../../assets//css//styles.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.related {
  background-color: gray;
}

.titleItem {
  font-family: SpectralLight;
  font-weight: 900;
}

.buttons-row {
  @include media-breakpoint-down(xs) {
    display: flex;
    justify-content: center;
  }
}
.price-item {
  border: 1px solid #272a59;
  border-radius: 0.2rem;
  color: #272a59;
  padding: 0.25rem 0.5rem;
  text-align: center;
  height: 31px;
  white-space: nowrap;
  max-width: 158px;
}
.autor-item {
  span {
    color: #4b95a6;
  }
}
.editorial-item {
  p {
    color: #343a40;
    font-family: sans-serif;
  }
  span {
    font-family: sans-serif;
    color: #1fa6a6;
    font-weight: bolder;
  }
  div:last-child {
    text-align: end;
    position: relative;
    top: 15px;
  }
}

.textButtonCart {
  font-family: sans-serif;
  font-weight: bold;
  background-color: var(--color-secundario);
  color: var(--texto-primario);
}

.buttonCart:hover {
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.img-portada {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.24);
}

.color-primary-c {
  button {
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    background: white;
    border: 1px solid #272a59;
    color: #272a59;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.5;
    }
  }
}

.description-item {
  justify-content: flex-end;
  p {
    text-align: justify;
    color: #343a40;
    font-family: sans-serif;
  }
  button {
    position: relative;
    top: -5px;
    left: -15px;
  }
}

.breadcrum-item {
  ol {
    background-color: transparent !important;
  }
}

.description {
  h5 {
    font-weight: bold;
    color: #343a40;
    font-family: sans-serif;
    text-align: center;
  }
}

.image-no-found {
  background: url("../../assets/img/image_not_found.png");
  background-size: cover;
  background-position: center center;
  width: 120px;
  height: auto;
}

.quantity-item {
  color: var(--color-primario);
  text-align: center;
  cursor: pointer;
}

.quantity-item:active {
  opacity: 0.7;
}

.detail {
  box-shadow: 0 0 14px 0 var(--color-sombra);
  border-radius: 10px;
}

.cover-container {
  $p: &;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  box-shadow: 0 0 1px 1px rgb(223, 223, 223);

  img {
    z-index: 1;
    width: 103%;
    object-fit: cover;
  }

  &--empty#{$p} {
    img {
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
  }
}
.socialDiv {
  display: flex;
  margin-top: 12px;
  .icon {
    background: #4b95a6;
    border-radius: 18px;
    width: 33px;
    height: 33px;
    margin-left: 8px;
  }
  svg {
    color: #fff;
    font-size: 20px;
    position: relative;
    left: 7px;
    top: 3px;
  }
}
.buttonsCol {
  .row:nth-of-type(1) {
    color: #f2a20d;
    svg {
      font-size: 23px;
    }
    svg,
    p {
      position: relative;
      top: 18px;
    }
    span {
      // color: #F2A20D;
      font-size: 33px;
    }
  }
  .buttonRow {
    button {
      width: 100%;
      background: #fff !important;
      color: #969595;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      padding: 6px 22px;
      svg {
        font-size: 23px;
      }
    }
    .addCart {
      background: #4b95a6 !important;
      border: 2px solid#4B95A6;
      color: #fff;
      display: block;
    }
    .inStock {
      border: 2px solid #f2a20d;
      svg {
        color: #f2a20d;
      }
    }
    .noStock {
      border: 2px solid #969595;
    }
  }
  .shippingTime {
    justify-content: center;
    color: #969595;
  }
  .gray {
    color: #969595;
  }
  .noticeAvailability {
    color: #4b95a6;
    font-size: 16px;
    img {
      width: 60px;
      height: 45px;
      margin-right: 10px;
    }
  }
  .placesAvailable {
    color: #000;
    font-size: 16px;
    justify-content: center;
    background: #f8f8f8;
    border-radius: 5px;
    align-items: flex-end;
    height: 70px;
    cursor: pointer;
    p {
      position: relative;
      top: -4px;
    }
    img {
      width: 45px;
      height: 52px;
      margin-right: 10px;
      position: relative;
      top: -8px;
    }
  }
  .detailRow {
    p {
      width: 100%;
      border-bottom: 1px solid #969595;
      color: #969595;
      margin: 5px;
    }
    ul {
      padding: 0px 5px;
    }
    li {
      list-style: none;
      color: #969595;
      font-size: 15px;
    }
    a {
      color: #006deb;
    }
  }
}

.commentsCol {
  .form-control {
    background: #f8f8f8;
  }
  .comTitle {
    width: 105%;
    color: #969595;
    font-size: 15px;
    border-bottom: 1px solid #c5c5c5;
    right: 2.3%;
  }
  .icon {
    position: relative;
    top: -5px;
  }
  button {
    font-size: 14px;
    padding: 2px 8px;
    float: right;
  }
  hr {
    border-top: 1px solid #dfdfdf;
    width: 106%;
    right: 2%;
    margin-bottom: 2px;
  }
  .commentRow {
    p {
      font-size: 16px;
    }
    svg {
      font-size: 21px;
      position: relative;
      top: -2px;
    }
  }
}
.markers.detail {
  top: 50px !important;
  left: -28px;
}
