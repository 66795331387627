@import "../../assets/css/styles.scss";

.homeContainer {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.05);
  padding: 0px 0px 25px 0px;
  margin-bottom: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  .containerPosts {
    width: 100%;
  }
  .titleHomeContainer {
    font-family: var(--fuente-primaria);
    font-weight: bolder;
    text-align: center;
    font-size: 25px;
  }
  .blogCategories {
    text-align: center;
    h6, a {
      color: #1d1d1d;
      text-decoration: none;
    }

    img {
      border-radius: 50%;
      border: 3px solid #F2A20D;
      padding: 6px;
      width: 110px;
      height: 110px;
      object-fit: cover;
    }
  }
}
.list-section {
  h3 {
    border-bottom: 1px solid rgba(205, 206, 207, 0.72);
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #969595;
  }
  div div {
    @include media-breakpoint-down(xs) {
      display: flex;
      justify-content: center;
    }
  }
  > .row:nth-of-type(2) {
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }
  .v-center {
    border-bottom: 1px solid rgba(205, 206, 207, 0.72);
    position: relative;
    bottom: 8px;
    right: 14px;
  }
}
.react-multi-carousel-item {
  width: 190px !important;
  border-right: 1px solid rgba(205, 206, 207, 0.664);
}
.react-multi-carousel-list {
  width: auto !important;
}
.react-multiple-carousel__arrow {
  &--right,
  &--left {
    border: 3px solid #ffffffbf !important;
  }
}
