@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.footer {
  background: white;
  width: 100%;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  box-shadow: 0 4px 35px -10px #ced4da;
  margin: 0px;
  .container {
    > div {
      @include media-breakpoint-down(md) {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  }
  .container {
    max-width: 68vw;
  }
  .information {
    margin-bottom: 15px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    width: 85%;
    overflow: hidden;
    @media (max-width: 1200px) {
      width: 100%;
    }
    .firstSection {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      img {
        max-height: calc(30px + 9vw);
        position: relative;
        top: 3.5vw;
        right: 4vw;
        right: 0px !important;
        margin: auto;
        @include media-breakpoint-down(sm) {
          top: 0px;
          right: 0px;
        }
      }
    }
    .secondSection:nth-of-type(3) {
      @media (min-width: 1200px) {
        flex: 0 0 22%;
        max-width: 22%;
        border-right: 1px solid white;
      }
      .nav-link p {
        cursor: pointer;
        width: max-content;
      }
    }
    .contSmall > .contact {
      @include media-breakpoint-down(sm) {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
    .secondSection,
    .thirdSection {
      @include media-breakpoint-down(sm) {
        position: relative;
        left: 12%;
      }
      @media (max-width: 405px) {
        left: 2%;
      }
      .table-footer {
        display: flex;
        justify-content: end;
      }
      .nav-link {
        padding: 0.2rem 0rem;
      }
    }
    .thirdSection {
      .nav-link p {
        cursor: pointer;
        margin: 5px -2px;
        height: 15px;
        position: relative;
        top: -5px;
      }
    }
    .contact {
      display: flex;
      .contactDetails {
        margin: 0.25rem 0 0 0.25rem;
        cursor: default;
        color: #fff;
        &:hover {
          color: #f2a20d;
        }
      }
      a:hover {
        text-decoration: none !important;
      }
    }
    .lastSection {
      font-family: "Lato";
      font-weight: bolder;
      position: relative;
      left: 45px;
      overflow: hidden;

      @media (max-width: 1200px) {
        margin: 15px 0px 10px;
        p,
        div {
          position: relative;
          left: 20%;
        }
      }
      @media (max-width: 405px) {
        p,
        div {
          left: 0%;
        }
      }
      @include media-breakpoint-down(sm) {
        margin-top: 4px;
        margin-bottom: 10px;
      }
      p {
        color: #fff;
        margin-top: 10px;
      }
    }
  }
  .bottom-footer {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 4px 35px -10px #ced4da;
    .copyright {
      width: 100%;
      display: flex;
      font-size: 13px;
      font-family: "Lato";
      font-weight: bolder;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(md) {
        margin: 0;
      }
      .markup {
        margin: 7px 0 10px;
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
    }
  }
}
.footer-links {
  background: #4b95a6;
  h5 {
    color: #fff;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    @include media-breakpoint-down(sm) {
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }
}
.textFooter {
  color: #ffffff;
  font-family: "Lato";
  font-size: 16px;
  p {
    max-width: 230px;
  }
  .nav-link {
    padding: 0px !important;
    color: #ffffff;
    &:hover {
      color: #f2a20d;
    }
    p {
      cursor: pointer;
    }
  }
  .icon-md {
    color: black;
  }
  &:hover {
    color: #f2a20d;
  }
}
.textFooter:active {
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
}
.title-footer {
  font-family: "Lato";
  font-weight: bolder;
}
