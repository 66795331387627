.instant-search {
  height: auto;
  max-height: calc(100vh - 100px);
  overflow-y: hidden;

  &__title {
    flex: 1 0 auto !important;
    max-height: 40px;

    .card-title {
      margin: 0 !important;
    }
  }

  &__results {
    overflow-y: scroll;
    display: block;
  }
}
