.header-mobile-desktop-nav {
  padding: 0.25em 15px 0.5em 0;
  display: flex;
  justify-content: space-between !important;
  .navbar-left {
    margin: 0px !important;
    padding-left: 7px;
    .show-desktop-scroll {
      padding: 5px 10px 0 10px;
    }
    .hamb-menu {
      display: flex;
      flex-direction: column;
      .lang-select-mobile {
        position: relative;
        top: 30px;
        left: 100%;
        width: 90px;
      }
      &-user {
        padding: 0.5rem 0;
        width: 100%;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: #f2a20d;
        }
        &:last-child {
          border-bottom: 1px solid #ced4da;
        }
        .ham-h {
          margin-left: 10px;
          margin-right: 8px;
          font-weight: 900;
          font-size: 24px !important;
          height: 18px;
          width: 100%;
          color: #4b95a6 !important;
          &.sign-in-alt {
            width: 26px !important;
          }
          &.i-user {
            font-size: 0.8em;
            width: 26px !important;
          }
        }
        .simple-hide-mobile {
          padding: 3px;
          color: #4b95a6;
          text-transform: uppercase;
          margin-bottom: 0px;
          &:hover {
            color: #f2a20d !important;
          }
        }
      }
    }
    .library-search {
      margin: 15px 0;
      &:hover {
        background-color: #969595;
        border-color: #969595;
      }
    }
  }
  #menu-right {
    display: flex;
    margin-top: 3px;
    @include media-breakpoint-down(md) {
      display: none;
    }
    .navbar-right {
      padding-top: 10px;
      .miniSearch-closing {
        z-index: 2;
        position: absolute;
        top: 23px;
        right: 200px;
        float: left !important;
        color: #321f20;
        cursor: pointer;
      }
      &__link {
        padding: 5px 0 5px 10px;
        display: inline-flex;
        font-size: 0.938em;
        text-transform: uppercase;
        text-decoration: none !important;
        height: 30px;
        cursor: pointer;
      }
      .fas.search-scroll {
        position: relative;
        // left: 0px;
        left: -10px;
        top: 2px;
        cursor: pointer;
        height: 20px !important;
      }
      .fas {
        font-weight: 900;
        font-size: 18px;
        line-height: 18px;
        height: 18px;
        width: 26px;
        // text-rendering: auto;
        color: #4b95a6;
        padding-right: 8px;
        &.sign-in-alt {
          font-size: 0.8em;
          width: 26px !important;
        }
      }
      .simple-hide-desktop {
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 0px !important;
        &:hover {
          color: #f2a20d;
        }
      }
    }
  }
}
