.filterCol {
  .select {
    width: 84%;
    // min-width: 75%;
  }
  svg {
    font-size: 27px;
    position: relative;
    top: 5px;
  }
  p {
    margin-bottom: 0px;
  }
  .filterTitle {
    font-weight: 900;
    font-size: 15px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 12px;
    & ~ p a,
    ~ p {
      text-decoration: none;
      color: #000;
      &:hover {
        color: #f2a20d !important;
        cursor: pointer;
      }
    }
  }
}
