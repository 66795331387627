.adminAsideRow {
  .card-header {
    width: 100%;
    height: 43px;
    button {
      width: 100%;
      height: 100%;
      background: #f2a20d;
      color: white;
      font-weight: 600;
      font-size: 13px;
      border-bottom: 1px solid rgb(231, 231, 231);
      text-align: left;
      svg {
        font-size: 18px;
        position: relative;
        top: -2px;
      }
    }
  }
  .card {
    .card-body {
      padding: 0px 0px;
      & > div > div {
        border-bottom: 2px solid rgb(199, 199, 199);
        padding: 0px;
        p {
          font-size: 14px;
          font-weight: 600;
          color: #4b95a6;
        }
      }
    }
  }
}
