.searchButton {
  position: relative;
  left: 888px;
  top: -69px;
  width: 165px;
  height: 25px;
}

.modifyPosition {
  position: relative;
  width: 15%;
  left: 56vw;
  top: -69px;
}

.form-style {
  width: 50vw;
  margin-left: -135px;
}

.modifyForm {
  margin-left: -205px;
}
.button {
  position: relative;
  top: -17px;
  left: 2vw;
}

.buttonDeleteQueryParams {
  border: 2px solid #17a2b8;
  width: 72px;
  height: 39px;
  color: #17a2b8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 54.5vw;
  top: -68px;

  &:hover {
    background-color: #17a2b8;
    color: white;
  }
}

.paddingColumn:nth-child(4n),
.paddingColumn:nth-child(5n),
.paddingColumn:last-child {
  padding: 0.76rem 3rem !important;
}
