@import './navMenuMobileDesktop';
@import './searchBars';
@import './productBarDesktop';

.header {
  $p: &;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 200;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0 rgba(0, 0, 0, 0.14), 0px 1px 10px 0 rgba(0, 0, 0, 0.12);

  &__main-nav {
    @include media-breakpoint-up(md) {
      padding: 0 !important;
    }

    @include media-breakpoint-down(md) {
      padding: 0.5em;
    }
  }

  &__nav {
    padding: 0px;
  }

  .navbar-expand-lg {
    display: block !important;
  }

  // ESTILOS BARRA SUPERIOR INFO
  #info-bar {
    background-color: #4b95a6;
    padding: 0 15px !important;
    .desktop-info {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      padding: 0px !important;

      &__items {
        display: flex;
        justify-content: flex-start;
        margin-top: 0;
        margin-bottom: 10px;
        padding-left: 0px;
        li {
          display: inline-block;
          margin-right: 24px;
          position: relative;
          top: 3px;
          list-style-type: none;
          a {
            font-size: 14px;
            color: #fff;
            border: none;
            text-decoration: none;
            &:hover {
              color: #f2a20d;
            }
          }
        }
      }

      .lang-bar {
        display: flex !important;
        justify-content: flex-end !important;
        .select-language {
          margin-top: 5px !important;
        }
        button {
          background: transparent;
          border: 1px solid #e7e7e7;
          border-radius: 0px;
          height: 28px;
          line-height: 1px;
        }
      }
    }

    #dropdown-list-button {
      font-size: 14px !important;
      background-color: transparent !important;
      color: #fff;
      border: none;
      padding: 0px;
      &:hover {
        color: #f2a20d;
      }
      &:focus {
        box-shadow: none !important;
      }
    }

    .dropdown-menu {
      margin-top: 7px;
      margin-left: 0px;
      min-width: 100%;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      .help-item {
        padding: 3px 20px;
        font-size: 14px !important;
        color: #000000 !important;
        &:hover {
          color: #f2a20d !important;
          background-color: transparent;
        }
      }
    }
  }

  // ESTILOS BARRA MOVIL
  #mobile-bar {
    padding: 0 15px;
    .mobile-menu {
      border: none;
      padding: 9px 10px;
      margin-top: 3px;
      span {
        margin-bottom: 0px !important;
      }
      &:hover {
        background-color: rgb(212, 214, 217);
      }
    }
    #mobile-simple-search-btn {
      position: absolute;
      left: 50px;
      top: 12px;
      cursor: pointer;
      .fas.search {
        color: #321f20;
        &:hover {
          color: #f2a20d;
        }
      }
    }
    .show-mobile {
      padding: 7px 10px;
    }
  }

  .show-desktop {
    display: flex;
    justify-content: center;
  }

  &__container {
    display: flex;
    align-items: center;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    margin: 0px 0px !important;
  }

  &__menu {
    ul {
      padding: 0;
      margin: 0;
      list-style: none outside none;
      display: flex;
      flex-direction: row;
    }

    li {
      + li {
        margin-left: 1em;
      }
    }
  }

  &__button {
    border: medium none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    // overflow: hidden;
    padding: 0;

    svg {
      padding: 0;
      margin: 0 !important;
      width: 24px;
      height: 24px;
    }

    &--cart {
      color: #fff;
      background-color: #f2a20d;
      position: relative;
      margin-top: 5px;
      .icon-md {
        color: var(--texto-secundario);
        &:hover {
          color: #000 !important;
        }
      }
      .cart-toast {
        position: absolute;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 95vw;
        padding: 0 3px;
        border-radius: 4px;
        font-size: 12px;
        height: 28px;
        right: -10px;
        &__detail {
          overflow: hidden;
          margin: 0 5px !important;
        }
        &:hover {
          color: black;
          text-decoration: none !important;
        }
      }

      .cart-counter {
        content: '';
        color: rgb(255, 255, 255);
        background-color: #17a2b8 !important;
        box-shadow: 2px 2px 4px rgba(black, 0.2);
        border-radius: 8px;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        display: block;
        min-width: 16px;
        height: 22px;
        padding: 1px 6px 1px 4px;
        position: absolute;
        left: calc(100% - 50px);
        top: -6px;
      }
    }
    &--desktopCart {
      .cart-text {
        position: absolute;
        white-space: nowrap;
        display: flex;
        align-items: center;
        border-radius: 4px;
        font-size: 14px;
        height: 30px;
        padding: 0 10px;
        z-index: 10;
        transform: translate(-50%, -50%);
        animation-name: cartTextMovingUp;
        animation-timing-function: ease-out;
        animation-duration: 3.3s;
        @keyframes cartTextMovingUp {
          0% {
            bottom: -120px;
          }
          100% {
            bottom: 150px;
          }
        }
        &__detail {
          color: #fff;
          margin-top: 10px;
        }
      }
    }

    &--user {
      background-color: var(--color-secundario);
      .icon-md {
        color: var(--texto-primario);
      }
    }

    &--logged {
      display: flex;
      .icon-sm {
        color: #4b95a6 !important;
        height: 21px !important;
        font-size: 18px;
      }
      a {
        margin-top: 5px;
        text-transform: uppercase;
        &:hover {
          text-decoration: none !important;
        }
      }
      span {
        color: rgba(0, 0, 0, 0.7);
        padding-left: 10px;
        margin-bottom: 5px;
        &:hover {
          color: #f2a20d;
        }
      }
      #dropdown-basic-button {
        display: flex;
        background-color: transparent !important;
        border: none;
        padding: 0;
        margin-top: 5px;

        span {
          color: rgba(0, 0, 0, 0.7);
          padding-left: 10px;
          margin-bottom: 5px;
          &:hover {
            color: #f2a20d;
          }
        }
        &:focus {
          box-shadow: none !important;
        }
        &:after {
          display: none;
        }
      }
      .dropdown-menu {
        top: 40px;
        left: -80px;
        right: 0;
        width: 175px;
        .dropdown-item {
          margin: 10px 0;
          padding: 5px 15px;
          font-size: 0.95rem;
          &:hover {
            background-color: transparent !important;
            color: #f2a20d !important;
          }
          svg {
            margin-right: 10px;
            font-size: 20px;
          }
        }
      }
    }
  }
  .nav-link:hover {
    color: #f2a20d !important;
  }
}

.logo {
  &--mobile {
    height: 45px;
  }
  &--desktop {
    height: 72px;
  }
  &--scroll {
    height: 35px;
  }
}

.close {
  display: inline-block;
  line-height: 1;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}

.btn-outline-dark.dropdown-toggle {
  &:focus {
    box-shadow: none !important;
  }
}

.customDrop {
  button {
    background: transparent;
    border: none;
    color: #131313;
    position: relative;
    top: 1.5px;
    &:hover {
      color: #f2a20d !important;
    }
  }
  .dropdown-toggle {
    background: transparent !important;
    border: none !important;
    color: #131313 !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  .dropdown-menu.show {
    width: 98vw;
    height: 310px;
    border-bottom: 2px solid #979797;
    > div:first-child {
      min-height: 290px;
    }
    a {
      text-decoration: none;
      &:hover {
        color: #f2a20d !important;
      }
    }
    li {
      margin-bottom: 6px;
      list-style: none;
      position: relative;
      right: 13px;
      &::before {
        color: rgb(209, 209, 209);
        font-weight: bolder;
        position: relative;
        right: 7px;
      }
    }
    .listItem {
      height: 26px;
      > a {
        width: 95%;
        border-bottom: 1px solid #e5e5e5;
        text-transform: uppercase;
        text-decoration: none;
        padding-bottom: 4px;
        &:hover {
          color: #f2a20d !important;
          background: transparent;
        }
      }
      p {
        width: 97%;
        text-transform: uppercase;
        font-size: 14px;
        &:hover {
          color: #f2a20d !important;
        }
      }
    }
    .listTitle {
      background: #e7e7e7;
    }
  }
  .dropdown-item.active {
    background: transparent;
  }
}
