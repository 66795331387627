@media (min-width: 300px) {
  .giftDiv {
    display: flex;
    flex-direction: column;
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .styleButton {
    position: none;
    width: 100%;
  }

  .containerGiftCard {
    padding-top: 15px;

    padding-left: 25px;
  }

  .containerInput {
    max-width: 100% !important;
  }

  .styleInput {
    width: 100% !important;

    &::placeholder {
      font-size: 11px;
    }
  }
  .styleTitle {
    font-size: 2em !important;
  }
}

@media (min-width: 576px) {
  .giftDiv {
    display: flex;
    flex-direction: column;
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .styleButton {
    position: none;
    width: 100%;
  }

  .containerGiftCard {
    padding-top: 15px;

    padding-left: 35px;
  }

  .containerInput {
    max-width: 100% !important;
  }

  .styleInput {
    width: 100% !important;
  }

  .styleTitle {
    font-size: 2.3em !important;
  }
}

@media (min-width: 776px) {
  .giftDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .styleButton {
    position: none;
    width: 100%;
  }

  .containerGiftCard {
    padding-top: 15px;

    padding-left: 95px;
  }

  .containerInput {
    max-width: 100% !important;
  }

  .styleInput {
    width: 580px !important;
  }

  .styleTitle {
    font-size: 2.3em !important;
  }
}

@media (min-width: 990px) {
  .giftDiv {
    display: flex;
    flex-direction: column;
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .styleButton {
    position: none;
    width: 100%;
  }

  .containerGiftCard {
    padding-top: 15px;

    padding-left: 95px;
  }

  .containerInput {
    max-width: 100% !important;
  }

  .styleInput {
    width: 580px !important;
  }

  .styleTitle {
    font-size: 2.3em !important;
  }
}

@media (min-width: 1180px) {
  .giftDiv {
    display: flex;
    flex-direction: column;
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .description {
    width: 100%;
  }

  .styleButton {
    position: none;
    width: 100%;
  }
  .containerGiftCard {
    padding-top: 15px;
    padding-right: 305px;
    padding-left: 305px;
  }

  .containerInput {
    max-width: 100% !important;
  }

  .styleInput {
    width: 580px !important;
  }

  .styleTitle {
    font-size: 2.3em !important;
  }
}

@media (min-width: 1270px) {
  .giftDiv {
    display: flex;
    flex-direction: column;
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .description {
    width: 100%;
  }

  .styleButton {
    position: relative;
    width: 100%;
  }

  .containerGiftCard {
    padding-top: 15px;
    padding-right: 305px;
    padding-left: 305px;
  }

  .containerInput {
    max-width: 100%;
  }

  .styleInput {
    width: 580px !important;
  }

  .styleTitle {
    font-size: 2.3em !important;
  }
}

@media (min-width: 1400px) {
  .giftDiv {
    display: flex;
    flex-direction: column;
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .description {
    width: 100%;
  }

  .styleButton {
    position: relative;
    width: 100%;
  }

  .containerGiftCard {
    padding-top: 15px;
    padding-right: 305px;
    padding-left: 305px;
  }

  .containerInput {
    max-width: 100%;
  }

  .styleInput {
    width: 580px !important;
  }

  .styleTitle {
    font-size: 2.3em !important;
  }
}
