.container-slider {
  margin-bottom: 20px;
  justify-content: center;
  .itemCarrousel {
    height: 100%;
    width: 100%;
    overflow: hidden;
    // border-radius: 10px;
    box-shadow: 0 0 14px 15px rgba(21, 22, 26, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .containerFileItem {
      width: 100%;
      height: 255px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: black;
      // border-radius: 10px;
      video,
      img {
        object-fit: cover;
        width: 100%;
        height: 260px;
        // border-radius: 10px;
        max-width: 100%;
        top: 0%;
        left: 0%;
        overflow: hidden;
        padding-bottom: 1px;
        @media (max-width: 800px) {
          video {
            width: 80%;
          }
        }
      }
    }
    .containerTextItem {
      padding-top: 1px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      transition: all 0.3s ease-in-out;
      .textItem {
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        background: black;
        opacity: 0.8;
        box-shadow: 0 0 14px 15px rgba(21, 22, 26, 0.05);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: justify;
        padding: 15px 20px 15px 20px;
        font-family: sans-serif;
        font-weight: bolder;
      }
      @media (max-width: 800px) {
        height: auto;
        .textItem {
          font-size: 12px;
        }
      }
    }
  }
  .carousel {
    position: relative;
    width: 102%;
    right: 1%;
  }
}
.carousel-control-prev {
  width: 4%;
  max-width: 4%;
  height: 255px;
  opacity: 0.85;
  background: rgb(96, 96, 96);
  background: linear-gradient(
    90deg,
    rgba(96, 96, 96, 0.5830707282913166) 8%,
    rgba(108, 108, 108, 0.38699229691876746) 16%,
    rgba(107, 107, 107, 0.23573179271708689) 30%,
    rgba(107, 107, 107, 0.16290266106442575) 53%,
    rgba(107, 107, 107, 0.06486344537815125) 72%,
    rgba(107, 107, 107, 0.00043767507002800965) 81%
  );
}
.carousel-control-next {
  width: 4%;
  max-width: 4%;
  height: 255px;
  opacity: 0.85;
  background: rgb(96, 96, 96);
  background: linear-gradient(
    270deg,
    rgba(96, 96, 96, 0.5830707282913166) 8%,
    rgba(108, 108, 108, 0.38699229691876746) 16%,
    rgba(107, 107, 107, 0.23573179271708689) 30%,
    rgba(107, 107, 107, 0.16290266106442575) 53%,
    rgba(107, 107, 107, 0.06486344537815125) 72%,
    rgba(107, 107, 107, 0.00043767507002800965) 81%
  );
}
